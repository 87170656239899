.expense-date {
    display: flex;
    flex-direction: column;
    width: 5.5rem;
    height: 5.5rem;
    border: 1px solid #ececec;
    background-color: rgb(58, 54, 54);
    color: white;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
  }

  .col__pad {
    padding: none !important;
    color: #ffffff !important;
    
  }
  
  .select_box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .select {
    border-radius: 15px;
    background-color: rgba(102, 101, 121, 0.575);
    outline: none !important; 
    height: 30px;   
    text-align:center;
    font-size: bolder;
    margin-top: 0.25rem;
    width: 90%;    
    
    color: #ffffff;
    
    font-style: italic;
    
}

@media screen and (max-width: 900px) {
    .select {
      width: 240px;
      
    }
   
}

@media screen and (max-width: 760px) {
  .select {
       
    padding-left: 2%;
      text-align:center;
      width: 95%;

      
  }
  
}


.block {
  width: 90%;
  align-items:center;
  justify-content: center;
  font-size: small;
  border: solid white;
  border-width: 1px;
  border-radius: 20px;
  padding: 12px 40px;
}

.button {
    border-radius: 15px;
    width: 130px;
    height: 50px;
    background-color: rgb(49, 153, 75);
    color: rgb(218, 218, 218);
    padding: 5px;
}

