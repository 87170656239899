.wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.item_wrapper {
    border: 2px solid;
    border-radius: 15px;
    padding: 20px;
    padding-top: 5px;
    margin-left: 20px;
    margin-top: 20px;   
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    overflow-y: auto;
    width: 320px;
}

.row_items_merger {
    display: flex;
    flex-direction: row;
}
.item_container {
    display: flex;
    width: 90%;
    min-width: 200px;
    flex-direction: row; 
    background-color:rgba(0, 255, 255, 0.233);
    border-radius: 15px; 
    padding: 5px;   
    cursor: pointer; 
    margin-bottom: 15px;
    
}
.index_container {
    height: 100%;
    width: 60px;
    display: flex;
    align-items:center;
    justify-content: center;
    padding-left: 10px;
    font-size: 30px;
    font-weight: 700;
    
}
.num_container {
    display: flex;
    align-items:center;
    justify-content: center;
    gap: 15px;
    width: 100%;
    
}

@media screen and (max-width: 1000px) {
    .wrapper {  
        width: 100%;      
       flex-direction: column;
       align-items: center;
    }
}

@media screen and (max-width: 350px) {
    .item_wrapper {        
        width: 270px;
    }
}
/* .wrapper {
    display: flex;
   
    align-items: flex-start;
} */
.icon_check_container {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
}