.padding_5 {
    padding: 5px !important;
}
.column_width_20 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 20px;
}
.column_width_30 {
    padding-left: 5px !important;
    padding-right: 5px !important;
    width: 30px;
}
.column_width_40 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 50px;
}
.column_width_50 {
    width: 50px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.column_width_70 {
    width: 70px;
    min-width: 70px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.column_width_80 {
    width: 90px;
    min-width: 90px;    
}
.column_width_100 {
    width: 100px;
}
.column_width_110 {
    width: 110px;
    min-width: 110px;
}
.column_width_120 {
    width: 130px;
    min-width: 130px;
}
.column_width_150 {
    width: 150px;
    min-width: 135px;
}
.column_width_180 {
    width: 180px;
    min-width: 180px;
}
.column_width_200 {
    width: 200px;
    min-width: 200px;
}
.column_width_250 {
    width: 250px;
    min-width: 250px;
}
.column_width_350 {
    width: 350px;
    min-width: 250px;
}
.txt_decor_offset {
    text-underline-offset: 5px; 
}


.row_height_30 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    height: 10px !important;
}

.padding_5 {
    padding: 5px !important;
}

.centered_container {
 height: 70vh;
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}

.set_button {
    padding: 1px 5px;
    margin-left: 5px;
    margin-bottom: 2px;
    border-radius: 5px;
}

.input_style {
    height: 31px;
    border-radius: 5px;
    outline: none !important;
    padding-left: 5px;
    width: 120px;
}

.input_style_2 {
    /* height: 31px;
    border-radius: 5px;
    outline: none !important; */
    /* padding-left: 5px; */
    width: 83px;
    
}
.orange_txt {
    color: rgb(255, 119, 0);
}
.mid_size_txt {
    font-size: smaller;
    font-weight: 300;
}
.sum_txt {
    font-size: large;
    font-weight: 300;
    letter-spacing: 2pt;
}

.max_width {
    max-width: 150px;
}
.centered_td {
    display: flex;
    align-items: center;
    justify-content: center;
}
.cendered_margine {
    margin-left: 20px;
    margin-right: 20px;
}


@media screen and (max-width: 900px) {
    .centered_td {
        display: flex;
        flex-direction: column;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
    .cendered_margine {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
  }

  .btn_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0;
  }
  .btn_item {
    border-radius:3px;
    font-size: large;
    letter-spacing: 2px;
  }
  .lg_txt {
    margin: 0 10px;
    font-size: x-large;
    letter-spacing: 3px;
  }
  .add_btn_spc > * {
    margin-left: 10px;
    margin-right: 10px;
  }
  .td_width_120 {
    min-width: 123px;
  }
  .td_width_130 {
    min-width: 127px;
  }
.year_box {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: x-large;
}
.cel_fixed_w_h {
    padding-left: 5px !important;
    padding-right: 5px !important;
    min-width: 120px;
    height: 10px !important;
}
.rel_pos {
    position:relative;
  
}
.fxd_cel {
    position: sticky;    
    left: 0px;
    height: 67px;
    z-index: 105;
    background-color: #343A40;
    font-size: large;
    /* style={{bottom : 67 *(test.length - index - 1)}} */
}
.wrapper_year_btn_cont {
 width: 100%;
 height: 100%;
 display: flex;
 align-items:center;
 justify-content: center;
}
.header_year_btn_cont {
    width: 100%;
    height: 100%;
    display: flex;
    align-items:center;
    justify-content: space-around;
}
.arrow_btn {
    border-radius: 5px;
    padding: 5px 10px;
}