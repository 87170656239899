.txt_sm {
    font-size: x-small;
    color: #FFFFFF;
  }

.red_txt {
  font-size: large;
  color: red;
}
.horizontal_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.vertical_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.orange_txt {
  color: rgb(255, 119, 0);  
}

.brown_txt {
  color:brown;
  background-color: burlywood;
  border-radius: 25px;
  padding: 5px 0;
  font-weight: bolder;
}
.white_txt {
  color: #FFFFFF;
}