.tab_container {
    display: flex;
    flex-direction: column;
   
    width: 100%;   
    min-height: 100%;
    background: #2d6694;
    margin: 1rem auto 1rem;
    word-break: break-all;
    border: 1px solid rgba(240, 240, 240, 0.274);
  }
  .tab_container_job_request {
    display: flex;
    flex-direction: column;   
    width: 100%;   
    min-height: 100%;
    background: #2d6694;
    margin: 1rem auto 0 auto;
    word-break: break-all;
    border: 1px solid rgba(240, 240, 240, 0.274);
  }
  .tab_container h3 {
    color: white;
    
  }
  .test {
    color:#1a1c1d;
  }
  
  .bloc-tabs {
   display: flex !important;
   flex-direction: row;
  }
  .tabs {
  
    
    text-align: center;
    width: 35%;
    background: rgba(128, 128, 128, 0.075);
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
  }
  /* .tabs:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  } */
  
  .active-tabs  {
    
    border-bottom: 1px solid transparent;
    height: 100%;
  }
  
  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background: rgb(88, 147, 241);
  }
  
  
  
  
  .content-tabs {
    flex-grow : 1;
    height: 100%;
  }
  .content {
    
   
    width: 100%;
    height: 100%;
    display: none;
  }
  .content h3 {
    padding: 0px 0 5px 0px;
  }
  .content hr {
    width: 100px;
    height: 2px;
    margin: 5px auto;
    
    background: white;
  }
  .content p {
    width: 100%;
    height: 100%;
  }
  .active-content {
    display: block;
    padding: 0 ;
    
  }

  .active-content h3, h4, h5 {
      padding: 1rem 0rem;
      color: #ffffff;
      line-height: 2.3rem;
  }

  .active-content hr {
    margin-left: 1rem;
}

.active-content p {
    padding: 1rem;
}


.tabbar__left > span {
  margin-right: 2rem;
  text-decoration: none;
  color: #a5aaad;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}

.tabbar__left .active_link {
  color: #ffffff;
  border-bottom: 3px solid #ffffff;
  padding-bottom: 0.8rem;
}

.tabbar_container {
 
  overflow: hidden;
}
.tabbar {
  height: 60px; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.text_invis {
  color:#ffffff00;
}

@media screen and (max-width: 900px) {
  .tabbar {
  
  }
}