.cell_esv {
    /* font-size: small; */
    color:rgb(240, 129, 77);
}
.cell_5p {
    font-size: large;
}
.cell_year {
    font-size: x-large;
}
.box_border {
    border: 3px dotted white;
       
}
.t_row_rides {
    min-width: 900px;
    max-width: 1300px;
    align-self: center;
}
.t_row_container {
    min-width: 900px;
    max-width: 1402px;
}
.t_cont_payments {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}