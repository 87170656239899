.container {
    color: black;
    font-family: Roboto;    
    padding: none;
    line-height: 100%;
    
}

.container__header {
    color: black;
    font-weight: bold;
    font-size: large;
}
.declaration__text {
    color: black;
}

.container__date__right {
    display: inline-flex;
    justify-content:flex-end;
    font-weight: bold;
    font-size:medium;
}
.bold_text {
    font-weight: bold;
}

.container__date__left {
    display: flex;
    justify-content:flex-start;
    font-weight: bold;
    font-size: medium;
    
}

.cell__top__left {
 border-bottom: black solid 1px;
 border-right: black solid 1px;
 border-top: black solid 1px;
 font-size: medium;
 font-weight: bold;
 display: flex;
 flex-direction: column;
 align-content: center;
 justify-content: center;
}

.cell__top__right {
    border-bottom: black solid 1px;    
    border-top: black solid 1px;    
    justify-content:left;
    padding: 5px;
    font-size: medium;
    line-height: 125%;
    text-align: left;
}

.cell__mid__left {
    border-bottom: black solid 1px;
    border-right: black solid 1px;    
    font-size: medium;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    line-height: 180%;
   }
   
   .cell__mid__right {
       border-bottom: black solid 1px;
       justify-content:left;
       padding: 5px;
       font-size: medium;
       text-align: left;
       line-height: 115%;
   }
   .text_body {
    text-align: left;
   }

   .cell__low__left {   
    border-right: black solid 1px;    
    font-size: medium;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    line-height: 180%;
   }
   
   .cell__low__right {       
       justify-content:left;
       padding: 5px;
       font-size: medium;
       text-align: left;
       line-height: 125%;

   }

   .cell__line__horisontal {
    border-bottom: black solid 1px;
   }

   .cell__line__vertical {
    border-left: black solid 1px;
   }

   .img__container {
    position: relative;
   }

   .img {
       position: absolute;
       left:40%;
       top: -35px;
   }

   .container__statement {
    line-height: 150%;
    font-size: large;
   }

   .left_stop {
       margin-left: 3rem;
       font-weight: bold;
   }

   .container__inner__statement {
       text-align: left;
       margin: 0 3rem;
   }

   .textBold {
    font-weight: bold;
   }

   .textCenter {
    text-align: center;
   }
   .alt_rep_signature {
    font-weight: normal; 
   }