.body {
   color: black;
   background-color: rgba(128, 173, 143, 0.774);
   min-height: 100vh;
   display: flex;
   justify-content: center;
  
}
.holder{
     max-width: 1180px;
}
.double_centered {
    display:flex;
    align-items:center;
    margin: 0 10px;
}
.top_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-around;
}
.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}
.col_aligned_left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.table_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
}
.table {
    
    display: flex;
    flex-direction: row;
    justify-content:left;
    border: black solid 2px;
    margin-top: 10px;
}
.table_header {
    display:flex;
    justify-content: center;
    align-items:center;
    border-bottom: black solid 2px;
    height: 65px;
}
.table_header_stackup {
    display: flex;
    flex-direction: column;
}
.table_header_split_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-top: black solid 1px;
}
.table_doublecell {
    display:flex;
    padding-left: 5px;
}
.table_cell_border_left {
    border-left: black solid 1px; 
}
.table_cell_border_right {
    border-right: black solid 1px; 
}
.table_cell_border_bottom {
    border-bottom: black solid 1px; 
}
.table_cell {
    padding: 5px;
    display:flex;
    justify-content: center;
    align-items:center;    
}
.taxi_service {
    width: 70px;
}
.border_2px {
    border-left: black solid 1px;
    border-right: black solid 1px;
    
}
.table_sum {
    background-color: rgba(223, 192, 68, 0.466);
    font-weight: bold;
    letter-spacing: 2px;
}
.flex_center {
    display: flex;
    align-items:center;
    justify-content: center;
}
.flex_col_centered {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}
.width_30 {
    font-size: smaller;
    width: 30px;
    padding: 2px 5px;
}
.width_50 {
    width: 50px;
}
.width_120 {
    width: 120px;    
}
.width_220 {
    width: 220px;    
}
.mr_x {
    margin:  12px 0;
    padding-right: 10px;
}
.font_x_small {
    font-size:x-small;
    padding: 0;
}
.font_xx_small {
    font-size:xx-small;
    padding: 0;
    font-weight: normal;
}
.text_invis {
    color:rgba(255, 235, 205, 0);
}
.red_text {
    font-weight: bold;
    color:red;
}

@media screen and (max-width: 800px) {
    .row, .double_centered, .top_row, .col_aligned_left, .table {
        flex-direction: column;
        justify-content: center;
        margin: 10px 0;
    }
    .sm_noshow {
       display: none;
    }
  }


