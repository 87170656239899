.container {
    color: black;
    font-family: Roboto;    
    padding: 0;
    margin: 0;
    line-height: 100%;
    width: 100%;
    
}
.text_invis {
    color:#ffffff00;
}
   .cell__line__horisontal {
    border-bottom: rgb(184, 184, 184) solid 1px;
   }

    .container__statement {
    line-height: 150%;
    font-size: large;
   }


   .container__inner__statement {
       text-align: left;
       margin: 0 3rem;
   }

   .textBold {
    font-weight: bold;
   }

   .textCenter {
    text-align: center;
   }

   .tel_link {
       color:#ffffff;
       cursor: pointer;
       line-height: 170%;
   }
   .tel_link_mar_btm {
    color:#ffffff;
    cursor: pointer;
   }
   .table_class td {
     padding: 12px 0 !important;      
   }


   .home {
       background-color: rgb(45, 102, 148);
       /* background-color: #111827; */
       color: rgb(218, 218, 218);      
       min-height: 100vh;
       line-height: 130%;
   }

   .borderBox {
    border: rgb(184, 184, 184) solid 1px;
    border-radius: 12px 0px 12px 12px ;
    box-shadow: 4px -4px rgba(141, 139, 141, 0.596);
   }

   .header {
       text-decoration-line: underline;
       text-underline-offset: 3px;
       text-shadow: none;
       color:rgb(221, 221, 221);
      
   }

   .link_style__white {
    color: rgb(221, 221, 221);
    
}

.totals {
    color: white;
    font-size: larger;
}

.modal_background {
    background-color: rgba(45, 102, 148, 0.849);
}

.test {
    background-color: blue;
    height: 30px;
    width: 350px;
    margin: 0 auto;
    padding: auto;
    border-radius: 20px;

}

.alert {
    text-shadow: none;
    font-weight: bold;
    font-family: Roboto;
    
}
.goback__btn__cont {
    display:flex;
    align-items:flex-end;
    justify-content: flex-end;
    color: #ffffff;
}
.t_row_rides {
    min-width: 900px;
}
.t_row_spend_details {
   
    min-width: 300px;
}
.t_row_details_container {
 width: 100%;
}

.col_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;
    padding-top: 1rem;
}
.t_row_rides {
    min-width: 900px;
}
.t_row_spends {
    min-width: 400px;
}
.col_side_limits {
    width: 50%;
}


.car_number {
 border: #fff solid 1px;
 border-radius: 10px;
}
.invis_text {
 color:#faebd700;
  }  
.car_number_flag_half {
    width: 15px;
    height: 9px;
}
.blue {
    background-color: blue;
} 
.yellow {
    background-color: yellow;
} 
.car_number_container {
    display: flex;   
    justify-content: flex-end;
    width: 100%;
   }
.itemDetails_buttons_block {
    display: flex;    
    align-items:center;
    justify-content: space-around;
}


.lower_btn_cont {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items:center;
}

 @media screen and (max-width: 900px) {
    .col_side_limits {
        width: 100%;
    }
    .car_number_container {
        justify-content: center;
         width: 100%;
    }
    .itemDetails_buttons_block {
        flex-direction: column;
    }
    .lower_btn_cont {
        flex-direction: column-reverse;
    }
  }

  .modal__form {
    text-align:center;
    color:black;
}
.flex_col_centered {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}


.date_number_container {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
     width: 100%;
}
.date_number_container_top {
    border-right: #fff solid 3px;
    border-left: #fff solid 3px;
    height: 5px;
    width:37px;
    margin-left: 5px;    
}

.date_number {    
    border: #fff solid 3px;
    border-top: #fff solid 7px;
    border-radius: 0 0 8px 8px;
    width:47px;    
   }

   .select_box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  @keyframes globe {
    0% {opacity: 1; 
        transform: scale(-1.2, 1.2);
        /* transform: translateY(30%); */
    }   
    100% {opacity: 0.5;
        transform: scale(1, 1);
        /* transform: translateY(50%); */
    }
}
  
  .globe_rotate {
    width: 80%;
    margin-left: 10%;     
    animation: globe 0.8s infinite alternate;
  }
  .gem_rotate {
    width: 80%;    
    animation: globe 0.8s infinite alternate;
  }
  .green_color {
       color: rgb(41, 33, 156);
  }

  .spinner_background {
      /* background-color:antiquewhite; */
      /* background: url(../pic/bg.jpg) no-repeat fixed bottom; */
      background: url(../pic/bg.jpg) no-repeat;
      background-size: cover;
      height: 90vh;
      margin-top: 3vh;
      padding-bottom: 3vh;
     
  }
  .pos_rell {
    position: relative;
  }
  .pos_abs {
    position: absolute;
    right: 0;
    top: 216px;
  }
  .abs_row {
    height: 103px; 
  }

  .abs_cell {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    border: none;
    height: 100%;
    min-height: 103px;
    width: calc(12vw - 2px);
  }
  .abs_row:nth-child(2n) {
    height: 105px;
  }
  .abs_cell:nth-child(2n) {
    height: 105px;
  }
  .ride_overlimit {
    font-size: small;
    color: red;
  }
  .x_small {
    font-size: x-small;
  }
  .fixed_width {
    width: 130px;
    min-width: 130px;
  }
  

  