.screen {
    background-color: rgba(45, 102, 148, 0.849);
    min-height: 100vh;
    min-width: 100vw;
    font-size: large;
}

.modal_background {
    background-color: rgba(45, 102, 148, 0.849);
}

.header {
    text-decoration-line: underline;
    text-underline-offset: 3px;
    text-shadow: none;
    color:rgb(255, 255, 255);
    font-weight: bold;
    font-size: large;
   
}
.header h3 {
    text-decoration-line: underline;
    text-underline-offset: 3px;
    text-shadow: none;
    color:rgb(255, 255, 255);
    font-weight: bold;
    font-size: large;
   
}

.ride_detail__img {
    width: 22rem;
}

.link_style__white :input-group-text {
    color: rgb(255, 255, 255);
    transform: scale(1.5,1.5);
    text-shadow: rgb(95, 91, 87) 1px 1px;
    font-size: medium;
    background-color:rgba(45, 102, 148, 0.849) ;
}
.car_button_agreement {
    width: 420px;
    padding-right: 12px;
}
.car_button_container {
    margin-top: 3rem;
}
.img_grid {
    max-width: 500px;
    margin: auto;
    padding: 0 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;   
}
.img_grid_privat {
    max-width: 500px;
    margin: auto;
    padding: 0 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;   
}
.img_grid_privat_ver2 {
    width: 500px;
    margin: auto;
    padding: 0 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;   
}
@media screen and (max-width: 550px) {
    .img_grid_privat_ver2{
        width: 300px;
        padding: 0;
        margin:0; 
    }
}
.white_line {
    color: white;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    height: 3px;    
    background-color: #fefeff;
}
@media screen and (max-width: 900px) {
    .img_grid_privat {
        grid-template-columns: 1fr; 
        grid-gap: 15px;
    }
    .car_button_container {
        width: 100%;
        margin-bottom: 2rem;
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .car_button_agreement {
        width: inherit;
    }
    .img_grid {
        
        grid-template-columns: 1fr;
    }
  }

  .car_link {
    margin-top: 3rem;
    cursor: pointer;
    width: 110px;
    height: 100px;
    margin: 30px 20px;
}
.car_link:hover {  
    cursor: pointer;
    background-color:rgba(76, 119, 156, 0.192);
    border-radius: 40px 40px 20px 20px;
    transform: scale(1.1);
    color: blue;
}
.car_link_pic {
    width: 100px;
    height: 100px;
}
.main_page_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}
.main_page {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;   
}


.car_card_container {
    position: relative;
    justify-self: center;
    width: 280px;
    height: 90px;
    background-color:rgba(0, 255, 255, 0.233);
    border-radius:20px;
    display: flex;
    flex-direction: row;
    transition: all 0.6s ease-in-out;
    z-index: 1;   
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.car_card_container:hover {
    background-color:rgba(0, 255, 255, 0.555);    
    transform: translate3d(10px, 10px, 50px) scale(1.1) ;     
    cursor: pointer;
    z-index: 1;    
}
.car_img_container {
    position: relative;
    /* background-color:rgba(185, 134, 185, 0.507); */
    height: 100%;
    width: 40%;
    z-index: 1;
}
.car_name_container {
    /* background-color:rgba(134, 185, 185, 0.507); */
    height: 100%;
    width: 60%;
    z-index: 1;
}
.car_img {   
    width: 87px;
    height: 87px;
    border-radius:50%; 
    position: absolute;
    top:2px;
    left: 2px;
    z-index: 1;
    filter: drop-shadow(10px 10px 4px #11325d62);
   
}
.car_plateNum {
    height: 50%;
    padding-top: 5px;
    margin-bottom: 10px;
    z-index: 1;
    text-shadow: 2px 2px #11325d62;
}
.car_plateNum_h6 {
    text-shadow: 2px 2px #11325d62;
}

@keyframes slide_in {
    0% {opacity: 0;
        transform: translateX(-100%);
    } 
    90% {
        opacity: 0.7;
        transform: translateX(5%);
    }  
    100% {opacity: 1;
        transform: translateX(0%);
    }
}  

.cards_wrapper {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    padding: 0;
    gap:20px;
    margin: 0;
    animation: slide_in 0.8s;
}

.show_on_lg_scr {
    display: block;
}
.top_btn_container {
    width: 100%;
    height: 60px;
    background-color:#343A40;
    display: none;
}
.car_icon {
    width: 60px;
    height: 40px;
    border: 1px solid;   
    margin: 10px 15px;
    padding: 3px 7px;
  
}
.slide_in_on_sm_scr {
    display: block;
}
.hide_block {
    display: none;
}
.show_block {
    display: block;    
}

@media screen and (max-width: 990px) {
   
    .top_btn_container {
        display: flex;
        flex-direction: row;
        align-items:flex-start;
    }
    .show_on_lg_scr {
        display: none;
    }
    
    .car_card_container {
        position: relative;
        width: 96%;
        height: 150px;       
    }
    .car_img {   
        width: 80%;
        height: 80%;
        border-radius:50%; 
        position: absolute;
        top:15px;
        left:10px;        
    }
    .cards_wrapper {
        position:absolute;
        z-index: 10;
        background-color:rgb(45, 102, 148);
        width: 350px;
        margin-bottom: 30px;
    }
    .main_page {
        height: 90vh;
    }
}
@media screen and (max-width: 400px) {
    .cards_wrapper {        
        width: 300px;
    }
}
@media screen and (max-width: 290px) {
    .cards_wrapper {        
        width: 250px;
    }
}

.mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color:#343a406a;
    border-radius: 20px;
    z-index: 20;
}
