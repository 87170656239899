

.paginateBox {
 width: 100%; 
 display: flex;
 justify-content: center;
 align-items: center;
}
.container {
 padding: 20px;
 margin-bottom: 35px;
 width: 80%;
 height: 40px;
 list-style: none;
 display: flex;
 justify-content: center;
}
.container a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid;
    cursor: pointer;
}
.container a:hover {
    font-weight: bold;
}
.active a {
    background-color: #ffffff;
    color:#2d6694 !important;
    font-weight: bold;
}
.previousBttn  {
    color:#ffffff !important;
}
.nextBttn {
    color:#ffffff !important;
}
