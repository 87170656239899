.home {
    min-height: 100vh;
    min-width: 100vw;
    color: #ffffff;
    font-weight: bolder;
    background-color:rgb(7, 58, 60);
   
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.home h1, h2, h3, h4, h5, h6 {
    color: #ffffff;
}

@media screen and (max-width: 900px) {
    .home {
        background-image: none;
        background-color: rgb(7, 58, 60); 
    }
}

.bck_grnd_1 {
    background: url("../assets/img_1.jpg") no-repeat center fixed;
    background-size: cover;
}
/* .bck_grnd_2 {
    background: url("../assets/img_2.jpg") no-repeat center center fixed;
    
} */
