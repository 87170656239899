.img_grid {
    margin: 20px auto;
    padding: 0 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
   
}
.delete_button {
    color: rgba(179, 52, 29, 0.616);   
    margin: 2rem;
    font-weight: bold;   
}
.doc_div {
    min-height: 270px;
}
.img_height {
    /* max-height: 350px;
    min-height: 250px; */
}

@media screen and (max-width: 900px) {
    .img_grid {
        
        grid-template-columns: 1fr;
    }
}