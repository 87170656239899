.App {
  text-align: center;
  color: #ffffff;
  min-height: 100vh;
  /* width: 100vw;
  overflow-x: hidden; */
  overflow-y: scroll;
  background-color: #111827;
  /* background-color: var(--main-bg) */
}









