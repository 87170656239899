.borderBox {
    border: rgb(184, 184, 184) solid 2px;
    border-radius: 12px 0px 12px 12px ;
    box-shadow: 4px -4px rgba(141, 139, 141, 0.596);
}
.header {
    text-decoration-line: underline;
    text-underline-offset: 3px;
    text-shadow: none;
    color:rgb(221, 221, 221);
}
.cell__line__horisontal {
    border-bottom: rgb(184, 184, 184) solid 1px;
}
.link_style__white {
    color: rgb(221, 221, 221);
    outline: none;
    border: none !important;
    width: 100%;
}
.text_invis {
    color:#ffffff00;
}
.test {
    color:#d14014;
}

.link_style__white:hover {
    color: rgb(255, 255, 255);
  
    font-size: larger;
}

.modal__form {
    text-align:center;
    color:black;
    max-height: 80vh;
    overflow-y: auto;
}
.modal__form2 {
    text-align:start;
    color:black;
    line-height: 30px;
   font-weight: 400;
}
.defenition {
    text-decoration: underline;
    font-weight: 600;
    font-size: large;
}

.hrivnya {
    font-size: large;
}
.totalsNum {
    font-size: larger;
}
.col_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;
}
.col_container_totals {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}
.t_row_rides {
    min-width: 900px;
}
.t_row_rides table th {
    padding: 0 !important;
}
.t_row_spends {
    min-width: 400px;
}
.t_row_totals {
    min-width: 900px;
}
.h4_dark_col {
    color:#1f1d1d;
}


@media screen and (max-width: 900px) {
    .col_side_limits {
        width: 100%;
    }
    .col_container {
        padding: 0 !important;
    }
  }
  @media screen and (max-width: 1205px) {
    .col_side_limits {
        width: 90%;
    }
  }
  .col_side_limits {
    width: 85%;
  }
  .bottom_note {
      display: flex;
      flex-direction: column;
     
  }
  .bottom_note_item {
   text-align:left;
   justify-content: left;
  }
  .bottom_note :first-child {
      text-decoration: underline;
      text-underline-offset: 3px;
      font-weight: bold;
      letter-spacing: 4px;
  }

  .button_col_container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
  }
.goback__btn__cont {
    width: 100%;
    display:flex;
    flex-direction: row;
    align-items:flex-end;
    justify-content: flex-end;
    color: #ffffff;
}

/* ////////// */
.button_container {
    display: flex;
    flex-direction: row;
}
  
.tabletop__btn__left {
    width: 50%;
    display:flex;
    align-items:flex-start;
    justify-content: flex-start;
    color: #ffffff;
}
.tabletop__btn__right {
    width: 50%;
    display:flex;
    flex-direction: row;
    align-items:flex-end;
    justify-content: flex-end;
    color: #ffffff;
}


  @media screen and (max-width: 900px) {
    .button_col {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 1rem 0;
    }
    .button_col_container {
        flex-direction: column;
        align-items:center;
        justify-content: center;
    }
    .button_container {
       
        flex-direction: column;
    }
    .tabletop__btn__right {
        width: 100%;      
    }
    .tabletop__btn__left {
        width: 100%;       
    justify-content: center;
    }
  }
.totals_cont {
    position: relative;
}
@keyframes fade-down {
    0% {opacity: 1; 
        transform: scale(1.1);
        transform: translateY(30%);
    }   
    100% {opacity: 0.2;
        transform: scale(0.7);
        transform: translateY(-10%);
    }
}
@keyframes fade-up {
    0% {opacity: 1; 
        transform: scale(1.1);
        transform: translateY(30%);
    }   
    100% {opacity: 0.2;
        transform: scale(0.7);
        transform: translateY(50%);
    }
}
.totals_arrow_up {
    position: absolute;
    right:37px;
    bottom: 0.01%;
    animation: fade-up 3s infinite alternate;  
}
.totals_arrow_down {
    position: absolute;
    right:25%;
    bottom: 35%;
    animation: fade-down 3s infinite alternate; 
    
}
@keyframes t_body {
    0% {opacity: 0;
        transform: translateX(-100%);
    } 
    90% {
        opacity: 0.7;
        transform: translateX(5%);
    }  
    100% {opacity: 1;
        transform: translateX(0%);
    }
}
.titem_tbody {
    transition: all 1.8s ease-in ease-out 2s; 
    animation: t_body 2s;
}
.del_btn_layout {
    display: flex;
    flex-direction: row;
}
 
.header_lower_block {
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items:center;
}
.header_lower_block_txt {
    width: 80%;
    /* background-color:#d14014; */
}
.header_lower_line {
    font-size: medium;
    letter-spacing: 2px;
    font-style: italic;
}

.footer_btn {
    width: 100%;   
    display: flex;
    justify-content: flex-end;
    gap: 30px;
}

.cert_preview_card {
    background: white;
    transition: all 0.2s ease;
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.cert_preview_card:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transform: translateY(-2px);
}

.cert_preview_pdf {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f9fa;
    cursor: pointer;
}

.cert_preview_image {
    max-height: 200px;
    width: 100%;
    object-fit: cover;
}

.cert_icon {
    font-size: 1.2rem;
    margin-right: 0.5rem;
}

.cert_info {
    margin-top: auto;
}

.cert_filename {
    margin-bottom: 0.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
