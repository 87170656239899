.card {
    background-color: rgb(45, 86, 119);
    border: none;
    max-width: 450px;
    padding: 15px;
    border-radius: 15px;
}

.todo_item_btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
}

.displayTodos ul {
    list-style: none;
    display: flex;
    align-self: flex-start;
    flex-wrap: wrap;
    padding-bottom: 20px;
    margin-bottom: 0 !important;
   
}
.displayTodos ul li {
    margin-left: 20px;
}
@media screen and (max-width: 1200px) {
    .displayTodos ul {        
        align-items: center;
        justify-content: center;
    }
}
@media screen and (max-width: 900px) {
    .displayTodos ul {
        padding: 0 !important;
        margin: 0 !important;       
    }
    /* .displayTodos ul li {
        padding: 0 !important;
        margin: 0 !important;
    } */
  }
