 
  .select_box {
    width: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

.select {
    border-radius: 15px;
    background-color: rgba(102, 101, 121, 0.575);
    outline: none !important; 
    height: 30px;   
    text-align:center;
    font-size: bolder; 
    letter-spacing: 1px;   
    width: 110px; 
    color: #ffffff;
    font-style: italic;
    
}




