.top_row_btns {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
   
}
.modal__form {
    text-align:center;
    color: #FFFFFF;
    text-shadow: 1px 1px 0 black;;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.footer_btn {
    width: 100%;   
    display: flex;
    justify-content: space-between;  
    flex-direction: column-reverse;  
    margin: 45px 0;  
    gap:30px;
}
.footer_btn_vault {
    width: 100%;   
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 23px;
    margin-bottom: 40px;       
}
.alert {

}
.modal__form2 {
    text-align:center;
    color:black;
}
.rel_div {
 position: relative; 
 width: 100%;
}
.abs_div {
    position: absolute;
    right: 20px;
    top: 12px;
}

.full_table {
    display: block;
}
.expandable_table_container {
    display: none;
}
@media (max-width: 995px) {
    .expandable_table_container {
      display: block;
      width: 100%;
      display: flex;
      justify-content: center;  
    }
    .full_table {
        display: none;
    }
    .top_row_btns {       
        flex-direction: column-reverse;
        justify-content: center;
        align-items:center;
        margin:30px auto;
        gap:25px;
        width: 350px;      
    }
    .footer_btn_vault {       
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 40px;       
    }
    .footer_btn {
      flex-direction: column-reverse;
      gap: 30px;
  }
  }
.expandable_table {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    /* background-color: #507a9b;  */
    /* Adjusted to match the blue background from your screenshot */
  }
  
  .table_row {
    background-color: #3d3d3d; /* Dark gray background for table rows */
    border-radius: 5px;
    color: #FFFFFF; /* Light yellow text */
    padding: 10px;
    border: 1px solid #2d2d2d; /* Slightly darker border */
    cursor: pointer;
  }
  
  .header_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header_row p {
    margin: 0;
    font-weight: bold;
    color: #FFFFFF; /* Light yellow text */
  }
  
  .expanded_content {
    margin-top: 10px;
    background-color: #444444; /* Slightly lighter gray */
    color: #ffffff; /* White text for readability */
    padding: 10px;
  }
  
  .details_table {
    width: 100%; /* Ensure table takes up full width of the parent container */
    border-collapse: collapse;
  }
  
  .details_table td {
    padding: 12px;
    border: 1px solid #2d2d2d; /* Border to separate table cells */
    vertical-align: top;
  }
  
  .details_table tr:nth-child(even) {
    background-color: #3d3d3d; /* Alternate row background color */
  }
  
  .details_btn {
    background-color: transparent;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .details_btn:hover {
    background-color: #505050; /* Hover effect */
  }
  
  .details_btn {
    background-color: transparent;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .details_btn:hover {
    background-color: #505050; /* Slight hover effect */
  }
  
  .toggle_button {
    background-color: #444444;
    color: #ffffff;
    border: none;
    font-size: 18px;
  }
  
  .toggle_button:hover {
    background-color: #505050; /* Hover effect for toggle button */
  }
  
  .red_str {
    color: red;
    font-size: xx-large;
  }

  .button {
    margin-right: 20px;
    padding: 0px 10px 5px 10px;
    font-size: 1.1rem;
    background-color: #505050;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #111827; /* Slightly darker on hover */
  }
  .key_cell {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap:10px;
  }
  .copy_cell {
    width: 20%;   
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .copy_cell_mob {
    width: 10%;   
    display: flex;
    flex-direction: row;
    justify-content: center;  
  }
  .pwd_cell {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;   
  }
  
  .pin_box {
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;   
    justify-content: space-between;
  }
  .pin_input {
    text-align: center;
    width: 33px;
    height: 35px;
    border-radius:5px;
    box-shadow: none;
    font-size: 1.5rem;
  }
  .input_box {
    position: relative;
  }
  .switch {
    position: absolute;
    right: 8%;
    top:10px;
    cursor: pointer;
  }

  .appended_btn:hover {
    cursor: pointer;    
  color: #FFFFFF !important;
  background-color: #213b44 !important;
  }