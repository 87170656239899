.button_container {
    display: flex;
    flex-direction: row;
}
  
.tabletop__btn__left {
    width: 50%;
    display:flex;
    align-items:flex-start;
    justify-content: flex-start;
    color: #ffffff;
}
.tabletop__btn__right {
    width: 50%;
    display:flex;
    flex-direction: row;
    align-items:flex-end;
    justify-content: flex-end;
    color: #ffffff;
}


  @media screen and (max-width: 900px) {
    
    .button_container {
       
        flex-direction: column;
    }
    .tabletop__btn__right {
        width: 100%;      
    }
    .tabletop__btn__left {
        width: 100%;       
    justify-content: center;
    }
  }
